import React from "react";
import "./App.css";
import Login from "./Login";
import Header from "./Header";
import GetPhotos from './Photos';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
export const AuthContext = React.createContext();
const initialState = {
  isAuthenticated: false,
  email: null,
  token: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("email", JSON.stringify(action.payload.email));
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      return {
        ...state,
        isAuthenticated: true,
        email: action.payload.email,
        token: action.payload.token
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        email: null
      };
    default:
      return state;
  }
};
function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
// return se ut så här när jag kan göra anrop
/*
(
    <AuthContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      <Header />
      <div className="App">{!state.isAuthenticated ? <Login /> : <GetPhotos />}</div>
    </AuthContext.Provider>
  );
*/
return (
  <main>
    <Switch>
    <AuthContext.Provider
      value={{
        state,
        dispatch
      }}
  >
    <div className="App">{!state.isAuthenticated ? <Login /> : <GetPhotos state={state} />}</div>
  </AuthContext.Provider>
    <Route path="/" component={Login} exact />
    <Route path="/gallery" component={GetPhotos} />
    <Route component={Login} />
    </Switch>
  </main>


);
}
export default App;