import React, { useState, useCallback, useEffect } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import loadingCloud from '../photos/loadingcloud.gif';
import SelectedImage from "./SelectImage";
import Selecto from "react-selecto";
import Header from "./Header";

function GetPhotos(state) {
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [files, setFiles] = useState(undefined)
  const toggleSelectAll = () => {
      setSelectAll(!selectAll);
  };

  const [selectImages, setSelectImages] = useState(false);

  const imageRenderer = useCallback(
      ({ index, left, top, key, photo }) => (
        <SelectedImage
          selected={selectAll ? true : false}
          key={key}
          margin={"2px"}
          index={index}
          photo={photo}
          left={left}
          top={top}
        />
      ),
      [selectAll]
    );
    useEffect(() => {
      if (isLoading) {
      let url = 'https://api.molnlagring.nu/getfiles?email=' + state.state.email + '&token=' + state.state.token + '&folder=photos&getAllFilesInFolder=true'  
      fetch(url).then(response => 
        response.json().then(data => ({
            data: data,
            status: response.status
        })
    ).then(res => {
        let file_urls = res.data['file_urls'];
        let gallery = []
        file_urls.forEach(function(entry) {
          let file = {
            src: entry,
            width: 4,
            height: 3
          }
          gallery.push(file);
        });
        setLoading(false);
        setFiles(gallery);
    }));
}});
  
    if (isLoading){
      return getLoadingScreen();
    } 
    const BasicRows = ()  => <Gallery photos={files} renderImage={imageRenderer} onClick={handleClick} />;

    return (
      <div className={"container"}>
            <Header />

          <Selecto
              container={document.body}
              dragContainer={"gallery"}
              selectableTargets={["container", "gallery"]}
              selectByClick={true}
              selectFromInside={true}
              continueSelect={false}
              toggleContinueSelect={"shift"}
              keyContainer={window}
              hitRate={100}
              onSelect={e => {
                  e.added.forEach(el => {
                      el.classList.add("selected");
                  });
                  e.removed.forEach(el => {
                      el.classList.remove("selected");
                  });
          }}
          />
          <div className={"gallery"}>
              <BasicRows />
          </div>

      </div>
      
  );
    
  
}
function handleClick(event) {

}

function getLoadingScreen() {
  return (
    <div>
          <Header />
      <img className={styles.loadingScreen} src={loadingCloud} />
    </div>
  )
}

const styles = {
  loadingScreen : {
    width: "4px",
    height: "3px",
  }
}




export default GetPhotos;

